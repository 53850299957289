import React, {useState} from 'react';
import {Link} from "react-router-dom";
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import AvTimerIcon from '@mui/icons-material/AvTimer';
import EventIcon from '@mui/icons-material/Event';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import {makeStyles} from "@mui/styles";
import {Avatar, Button, Card, CardActions, CardContent, CardHeader, Grid} from "@mui/material";
import {blueGrey, green} from "@mui/material/colors";
import InfoIcon from "@mui/icons-material/Info";
import ClassIcon from '@mui/icons-material/Class';

const InstituteMeasure = (props) => {

    const useStyles = makeStyles((theme) => ({
        root: {
            display: 'flex',
            '& > *': {
                margin: theme.spacing(1),
            },
        },
        green: {
            color: '#fff',
            backgroundColor: green[800],
        },
        blueGrey: {
            color: '#fff',
            backgroundColor: blueGrey[500],
        },
    }));

    const instituteMeasure = props.instituteMeasure;
    const showActionButton = props.showActionButton;

    const classes = useStyles();

    const [state, setState] = useState({
        raised: false,
        shadow: 1,
    })



    return (
        <Card className="card-content"
              onMouseOver={() => setState({raised: true, shadow: 4})}
              onMouseOut={() => setState({raised: false, shadow: 1})}
              raised={state.raised} zdepth={state.shadow}>
            <CardHeader
                avatar={<Avatar aria-label="recipe" className={classes.green}>{instituteMeasure.measure.charAt(0).toUpperCase()}</Avatar>}
                title={instituteMeasure.description}
                subheader={instituteMeasure.institute.name}
            />
            <CardContent>

                <Grid container direction={showActionButton ? 'column' : 'row'} justifyContent="space-between" alignItems="start">
                    <Grid item xs={4}>
                        <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                            <Grid item>
                                <Avatar variant="rounded" alt="Durum" className={classes.blueGrey}>
                                    <ManageSearchIcon/>
                                </Avatar>
                            </Grid>
                            <Grid item>
                                {instituteMeasure.stateType}
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={4}>
                        <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                            <Grid item>
                                <Avatar variant="rounded" alt="Başlangıç / Bitiş Tarihi" className={classes.blueGrey}>
                                    <EventIcon/>
                                </Avatar>
                            </Grid>
                            <Grid item>
                                {instituteMeasure.startDate} - {instituteMeasure.endDate}
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={4}>
                        <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                            <Grid item>
                                <Avatar variant="rounded" alt="Ölçüm Methodu" className={classes.blueGrey}>
                                    <AvTimerIcon/>
                                </Avatar>
                            </Grid>
                            <Grid item>
                                {instituteMeasure.measure}
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={4}>
                        <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                            <Grid item>
                                <Avatar variant="rounded" alt="Eğitimci" className={classes.blueGrey}>
                                    <AssignmentIndIcon/>
                                </Avatar>
                            </Grid>
                            <Grid item>
                                {instituteMeasure.trainer.name} {instituteMeasure.trainer.surname}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </CardContent>
            {showActionButton &&
                <CardActions>
                    <Link to={`/measure/status/${instituteMeasure.id}`} className={classes.cardAction}>
                        <Button variant="outlined" startIcon={<InfoIcon/>}>DETAY</Button>
                    </Link>
                    {instituteMeasure.active &&
                    <Link to={`/classroom/${instituteMeasure.institute.id}/measure/${instituteMeasure.id}`}
                          style={{marginLeft: 'auto'}}>
                        <Button variant="contained" startIcon={<ClassIcon/>}>Sınıflar</Button>
                    </Link>
                    }
                </CardActions>
            }
        </Card>

    );
};

export default InstituteMeasure;
