import {useEffect} from "react";

import {useSnackbar} from "notistack";
import StudentApi from "../../api/Students";
import {useForm} from "react-hook-form";
import CloseIcon from '@mui/icons-material/Close';
import {
    AppBar,
    Button,
    Dialog,
    DialogContent,
    DialogContentText,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Toolbar,
    Typography
} from "@mui/material";
import {makeStyles} from "@mui/styles";

const StudentEdit = ({closeDialog, isOpen, classroom, id}) => {

    const useStyles = makeStyles((theme) => ({
        appBar: {
            position: 'relative',
        },
        title: {
            marginLeft: theme.spacing(2),
            flex: 1,
        },
    }));

    const {register, handleSubmit, reset, formState: {errors}} = useForm();

    const isAddMode = !id;

    const classes = useStyles();

    const {enqueueSnackbar} = useSnackbar();

    const handleClose = () => {
        reset();
        closeDialog();
    };

    useEffect(() => {
        console.log('open' + isOpen);
    }, [isOpen]);


    const onSubmit = (data) => {

        Object.assign(data, {'classroomId': classroom.id});

        StudentApi.save(data)
            .then(() => {
                enqueueSnackbar('Öğrenci eklendi', {variant: 'success'});
                handleClose();
            })
            .catch(error => {
                if (!error.response || !error.response.data) {
                    enqueueSnackbar('Kayıt da hata ile karşılaşıldı', {variant: 'error'});
                } else {
                    error.response.data.uiMessages.errors.map((uiError) => enqueueSnackbar(uiError.message, {variant: 'error'}));
                }

            });
    };

    return (
        <Dialog fullScreen open={isOpen} onClose={handleClose}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <AppBar className={classes.appBar}>
                    <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                            <CloseIcon/>
                        </IconButton>
                        <Typography variant="h6" className={classes.title}>
                            {classroom.name} Öğrenci Tanımlama
                        </Typography>
                        <Button autoFocus color="inherit" onClick={handleSubmit(onSubmit)}>
                            Kaydet
                        </Button>
                    </Toolbar>
                </AppBar>
                <DialogContent>
                    <DialogContentText>

                    </DialogContentText>
                    <TextField autoFocus margin="dense" id="name" label="Adı" type="text" {...register("name", {required: true})} fullWidth/>
                    <TextField margin="dense" id="surname" label="Soyadı" type="text" {...register("surname", {required: true})} fullWidth/>
                    <TextField margin="dense" id="academyNo" label="Kurum No" type="text" {...register("academyNo")} fullWidth/>

                    <InputLabel id="sex-label">Cinsiyeti</InputLabel>

                    <Select
                        labelId="sex-label"
                        label="Cinsiyet"
                        {...register("sex", {required: true})}
                        fullWidth
                    >
                        <MenuItem value='MALE'>Erkek</MenuItem>
                        <MenuItem value='FEMALE'>Kız</MenuItem>
                    </Select>

                    <InputLabel id="birthMonth-label">Doğum Ay</InputLabel>
                    <Select
                        labelId="birthMonth-label"
                        label="Doğum Ay"
                        {...register("birthMonth")}
                        fullWidth
                    >
                        <MenuItem value='1'>Ocak</MenuItem>
                        <MenuItem value='2'>Şubat</MenuItem>
                        <MenuItem value='3'>Mart</MenuItem>
                        <MenuItem value='4'>Nisan</MenuItem>
                        <MenuItem value='5'>Mayıs</MenuItem>
                        <MenuItem value='6'>Haziran</MenuItem>
                        <MenuItem value='7'>Temmuz</MenuItem>
                        <MenuItem value='8'>Ağustos</MenuItem>
                        <MenuItem value='9'>Eylül</MenuItem>
                        <MenuItem value='10'>Ekim</MenuItem>
                        <MenuItem value='11'>Kasım</MenuItem>
                        <MenuItem value='12'>Aralık</MenuItem>
                    </Select>

                    <TextField margin="dense" id="birthYear" label="Doğum Yılı" type="number"
                               {...register("birthYear", {
                                   valueAsNumber: true, minLength: 4, maxLength: 4, required: true
                               })} fullWidth/>

                    <TextField margin="dense" id="identityNo" label="Kimlik No" type="number" {...register("identityNo")} fullWidth/>
                    <TextField margin="dense" id="email" label="Eposta" type="email" {...register("email")} fullWidth/>

                </DialogContent>
            </form>
        </Dialog>
    );
};

export default StudentEdit;

