import React from 'react';
import {Link} from 'react-router-dom';
import {Grid} from "@mui/material";

const ShowError = () => (
        <Grid
            container
            direction="column"
            justifyContent="flex-end"
            alignItems="center"
            style={{height: '50vh'}}
        >
            <div id="wrapper">
                <img src="/network_error.png" width={150}/>
                <div id="info">
                    <h3>Erişim hatası</h3>
                    <h4>Bağlantınızı kontrol edip tekrar deneyiniz!</h4>
                </div>
            Yeniden giriş yapmak için 'Giriş' tıklayınız. <Link to="/login">Giriş</Link>
            </div>
        </Grid>
    )
;

export default ShowError;