import React from 'react';
import ReactDOM from 'react-dom';
import './assets/index.css';
import App from './App';
import {createTheme, ThemeProvider} from "@mui/material/styles";
import {CssBaseline} from "@mui/material";

const theme = createTheme();

ReactDOM.render(
    <ThemeProvider theme={theme}>
        <CssBaseline/>
        <App/>
    </ThemeProvider>
    , document.getElementById('root')
);

