import React from 'react';
import {Link} from 'react-router-dom';
import {Grid} from "@mui/material";

const NotFound = () => (
    <Grid
        container
        direction="column"
        justifyContent="flex-end"
        alignItems="center"
        style={{height: '50vh'}}
    >
        <div id="wrapper">
            <img src="/404.png"/>
            <div id="info">
                <h3>Aradığınız Sayfa Bulunamadı!</h3>
            </div>
            <Link to="/">Ana Sayfa</Link>
        </div>
   </Grid>
);

export default NotFound;