import {useContext, useEffect, useState} from "react";
import CloseIcon from '@mui/icons-material/Close';
import {makeStyles} from "@mui/styles";
import {
    AppBar,
    Button,
    Dialog,
    DialogContent,
    DialogContentText,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Toolbar,
    Typography
} from "@mui/material";
import {useSnackbar} from "notistack";
import {useForm} from "react-hook-form";
import {SeasonContext} from "../../config/SeasonContext";
import ClassroomApi from "../../api/Classrooms";
import TeacherApi from "../../api/Teachers";
import InstituteApi from "../../api/Institutes";

const ClassroomEdit = ({closeDialog, isOpen, instituteId, id}) => {

    const useStyles = makeStyles((theme) => ({
        appBar: {
            position: 'relative',
        },
        title: {
            marginLeft: theme.spacing(2),
            flex: 1,
        },
    }));
    const isAddMode = !id;

    const {register, handleSubmit, reset, formState: {errors}} = useForm();
    const season = useContext(SeasonContext);
    const classes = useStyles();

    const [classLevels, setClassLevels] = useState([]);
    const [teachers, setTeachers] = useState([]);

    const {enqueueSnackbar} = useSnackbar();

    const getClassLevels = () => {
        InstituteApi.getClassLevels(instituteId).then(response => setClassLevels(response.data.data));
    }

    const getTeachers = () => {
        TeacherApi.getByInstituteId(instituteId).then(response => setTeachers(response.data.data));
    }

    const handleClose = () => {
        reset();
        closeDialog();
    };

    useEffect(() => {
        getClassLevels();
        getTeachers();

    }, [instituteId]);

    const onSubmit = (data) => {

        Object.assign(data, id);
        Object.assign(data, {instituteId: instituteId});
        Object.assign(data, {seasonId: season.id});

        ClassroomApi.save(data)
            .then(() => {
                enqueueSnackbar('Sınıf eklendi', {variant: 'success'});
                handleClose();
            })
            .catch(error => {
                if (!error.response || !error.response.data) {
                    enqueueSnackbar('Kayıt da hata ile karşılaşıldı', {variant: 'error'});
                } else {
                    error.response.data.uiMessages.errors.map((uiError) => enqueueSnackbar(uiError.message, {variant: 'error'}));
                }

            });
    };


    return (
        <Dialog fullScreen open={isOpen} onClose={handleClose}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <AppBar className={classes.appBar}>
                    <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                            <CloseIcon/>
                        </IconButton>
                        <Typography variant="h6" className={classes.title}>
                            Sınıf Tanımlama
                        </Typography>
                        <Button autoFocus color="inherit" onClick={handleSubmit(onSubmit)}>
                            Kaydet
                        </Button>
                    </Toolbar>
                </AppBar>
                <DialogContent>
                    <DialogContentText>

                    </DialogContentText>
                    <TextField autoFocus margin="dense" id="name" label="Adı" type="text" {...register("name")} fullWidth/>
                    <InputLabel id="measure-label">Sınıf Grubu</InputLabel>

                    <Select
                        labelId="trainerId-label"
                        label="Sınıf Grubu"
                        {...register("classLevelCode", {required: true})}
                        fullWidth
                    >
                        {classLevels?.map(classLevel => (
                            <MenuItem value={classLevel.code}>{classLevel.name}</MenuItem>
                        ))}
                    </Select>
                    <InputLabel id="measure-label">Öğretmen</InputLabel>

                    <Select
                        labelId="trainerId-label"
                        label="Eğitmen"
                        {...register("teacherId", {required: true})}
                        fullWidth
                    >
                        {teachers?.map(teacher => (
                            <MenuItem value={teacher.id}>{teacher.name} {teacher.surname}</MenuItem>
                        ))}
                    </Select>

                    <InputLabel id="activeType-label">Aktiflik</InputLabel>
                    <Select
                        labelId="activeType-label" defaultValue={'ACTIVE'}
                        {...register("activeType", {required: true})}
                        fullWidth
                    >
                        <MenuItem value="ACTIVE">Aktif</MenuItem>
                        <MenuItem value="PASSIVE">Pasif</MenuItem>
                        <MenuItem value="SUSPEND">Askıda</MenuItem>
                    </Select>
                </DialogContent>
            </form>
        </Dialog>
    );
};

export default ClassroomEdit;

