import React, {useEffect, useState} from 'react';
import {Link, useNavigate, useParams} from "react-router-dom";
import InstituteMeasureApi from "../../api/InstituteMeasures";
import InstituteMeasure from "../../component/institute-measure/InstituteMeasure";
import {useSnackbar} from "notistack";
import {Button, Card, Divider, Grid, Typography} from "@mui/material";
import CheckIcon from '@mui/icons-material/Check';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import ClassIcon from "@mui/icons-material/Class";
import {Progress} from "../../types/Progress";
import TableChartIcon from '@mui/icons-material/TableChart';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import {TotalSummary} from "../../types/TotalSummary";
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineDot from '@mui/lab/TimelineDot';
import AccessTimeIcon from '@mui/icons-material/AccessTime';

const MeasureStatus = () => {

    const navigate = useNavigate();

    const {measureId} = useParams();

    const {enqueueSnackbar} = useSnackbar();

    const [measure, setMeasure] = useState();
    const [measureStatus, setMeasureStatus] = useState();
    const [notifications, setNotifications] = useState();

    const getMeasure = () => {
        InstituteMeasureApi.getStatusById(measureId).then(response => {
            const responseStatus = response.data.data;
            setMeasureStatus(responseStatus);
            setMeasure(responseStatus.instituteMeasure);
        }).catch(error => {
            if (!error.response || !error.response.data) {
                enqueueSnackbar('Ölçüm alınırken hata alındı ', {variant: 'error'});
            } else {
                error.response.data.uiMessages.errors.map((uiError) => enqueueSnackbar(uiError.message, {variant: 'error'}));
            }

        });

    }

    const getMeasureNotifications = () => {
        InstituteMeasureApi.getNotificationById(measureId).then(response => {
            setNotifications(response.data.data);
        }).catch(error => {
            if (!error.response || !error.response.data) {
                enqueueSnackbar('Ölçüm alınırken hata alındı ', {variant: 'error'});
            } else {
                error.response.data.uiMessages.errors.map((uiError) => enqueueSnackbar(uiError.message, {variant: 'error'}));
            }

        });
    }

    const handleMeasureComplete = () => {
        const answer = window.confirm('Ölçme / Değerlendirme girişi durdurulacak. Onaylıyor musunuz ?');
        if (!answer) {
            return;
        }
        InstituteMeasureApi.saveMeasureCompleted(measureId).then(response => {

            setMeasure(response.data.data)
            enqueueSnackbar('Ölçüm tamamlandı', {variant: 'success'});
        }).catch(error => {
            if (!error.response || !error.response.data) {
                enqueueSnackbar('Günceleme yapılırken hata oluştu ', {variant: 'error'});
            } else {
                error.response.data.uiMessages.errors.map((uiError) => enqueueSnackbar(uiError.message, {variant: 'error'}));
            }

        });
    }

    const handleStartAnalyse = () => {
        const answer = window.confirm('Analiz başlatılacak. Onaylıyor musunuz ?');
        if (!answer) {
            return;
        }
        InstituteMeasureApi.saveStartAnalyse(measureId).then(response => {

            setMeasure(response.data.data)
            enqueueSnackbar('Ölçüm analizi başladı', {variant: 'success'});
        }).catch(error => {
            if (!error.response || !error.response.data) {
                enqueueSnackbar('Günceleme yapılırken hata oluştu', {variant: 'error'});
            } else {
                error.response.data.uiMessages.errors.map((uiError) => enqueueSnackbar(uiError.message, {variant: 'error'}));
            }
        });
    }

    const handleReMeasure = () => {

        const answer = window.confirm('!!!! Bütün değerlendirme raporları SİLİNECEK ve bilgi girişi açılacak. Onaylıyor musunuz ?');
        if (!answer) {
            return;
        }
        InstituteMeasureApi.saveMeasureRemeasure(measureId).then(response => {

            setMeasure(response.data.data)
            enqueueSnackbar('Ölçüm tekrar başladı', {variant: 'success'});
        }).catch(error => {
            if (!error.response || !error.response.data) {
                enqueueSnackbar('Günceleme yapılırken hata oluştu', {variant: 'error'});
            } else {
                error.response.data.uiMessages.errors.map((uiError) => enqueueSnackbar(uiError.message, {variant: 'error'}));
            }

        });
    }

    const handleMeasureDelete = () => {

        const answer = window.confirm('!!!! ÖLÇÜM SİLİNECEK. Onaylıyor musunuz ?');
        if (!answer) {
            return;
        }
        InstituteMeasureApi.deleteMeasure(measureId).then(response => {
            enqueueSnackbar('Ölçüm başarıyla silindi', {variant: 'success'});
            navigate('/');
        }).catch(error => {
            if (!error.response || !error.response.data) {
                enqueueSnackbar('Silinken hata oluştu', {variant: 'error'});
            } else {
                error.response.data.uiMessages.errors.map((uiError) => enqueueSnackbar(uiError.message, {variant: 'error'}));
            }
        });
    }


    useEffect(() => {
        getMeasure();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        getMeasureNotifications();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [measure]);


    return (<div>
        <h2>{measure?.description}</h2>


        <Grid
            container
            direction="row"
            justifyContent="space-around"
            alignItems="flex-start"
        >
            <TotalSummary title="Yetenek" color='secondary' total={measureStatus?.countOfSkills}/>
            <TotalSummary title="Sınıf" color='error' total={measureStatus?.countOfClassrooms}/>
            <TotalSummary title="Öğrenci" color='success' total={measureStatus?.countOfStudents}/>
            <TotalSummary title="Ölçüm" total={measureStatus?.countOfStudentMeasures}/>
        </Grid>

        <Progress progress={measureStatus?.countOfStudents > 0 ? measureStatus?.countOfStudentMeasures / measureStatus?.countOfStudents * 100 : 0}/>

        <Divider variant="middle"/>

        {measure && <InstituteMeasure instituteMeasure={measure} showActionButton={false}/>}

        <Grid
            container
            direction="column"
            justifyContent="space-between"
            alignItems=""
        > <Card className="card-content">
            {measure?.stateType === 'MEASURE' &&  measure?.active &&
                <Button variant="contained" color="success" startIcon={<CheckIcon/>} onClick={handleMeasureComplete}>Ölçümü Tamamla</Button>}
            {measure?.stateType === 'MEASURE_COMPLETED' &&  measure?.active &&
                <Button variant="contained" color="primary" startIcon={<QueryStatsIcon/>} onClick={handleStartAnalyse}>Analiz Başlat</Button>}
            {measure?.stateType === 'ANALYSE_COMPLETED' &&  measure?.active &&
                <Button variant="outlined" color="error" startIcon={<RestartAltIcon/>} onClick={handleReMeasure}>Ölçüme Tekrar Başla</Button>}

            {measure?.stateType === 'MEASURE' && measure?.active &&
                <Link to={`/classroom/${measure.institute.id}/measure/${measure.id}`}
                      style={{marginLeft: 'auto'}}>
                    <Button variant="contained" startIcon={<ClassIcon/>}>Sınıflar</Button>
                </Link>}
            {measure?.stateType === 'ANALYSE_COMPLETED' &&
                <Link to={`/classroom/${measure.institute.id}/measure/${measure.id}`}
                      style={{marginLeft: 'auto'}}>
                    <Button variant="contained" startIcon={<TableChartIcon/>}>Değerlendirme Raporu</Button>
                </Link>}

            {measure?.stateType === 'MEASURE' && measureStatus?.countOfStudentMeasures === 0 &&
                <Button variant="contained" color="error" startIcon={<DeleteForeverIcon/>} onClick={handleMeasureDelete}>Ölçümü Sil</Button>}

        </Card>
        </Grid>



        <Timeline position="alternate">
            {notifications?.map((notification, index) => {
                return (
                    <TimelineItem key={index}>
                        <TimelineOppositeContent
                            sx={{m: 'auto 0'}}
                            variant="body2"
                            color="text.secondary"
                        >
                            {notification.createdAt}
                        </TimelineOppositeContent>
                        <TimelineSeparator>
                            <TimelineConnector/>
                            <TimelineDot color="info">
                                <AccessTimeIcon/>
                            </TimelineDot>
                            <TimelineConnector/>
                        </TimelineSeparator>
                        <TimelineContent sx={{py: '12px', px: 2}}>
                            <Typography variant="subtitle2" component="span" align="center">
                                {notification.message}
                            </Typography>
                        </TimelineContent>
                    </TimelineItem>
                );
            })}
        </Timeline>

    </div>);
};

export default MeasureStatus;
