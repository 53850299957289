import React, {useEffect, useState} from 'react';
import ClassroomApi from "../../api/Classrooms";
import {useParams} from "react-router-dom";

import StudentApi from "../../api/Students";
import MaterialTable from "material-table";
import {tableIcons, tableLocationsTR} from "../../config/Constant";
import Classroom from "../classroom/Classroom";
import {useSnackbar} from "notistack";
import {Grid} from "@mui/material";

const StudentList = () => {

    const {classroomId} = useParams();
    const {enqueueSnackbar} = useSnackbar();
    const [classroom, setClassroom] = useState();
    const [students, setStudents] = useState([]);

    const getStudents = () => {
        StudentApi.getByClassroomId(classroomId).then(response => setStudents(response.data.data));
    }

    const getClassroom = () => {
        ClassroomApi.get(classroomId).then(response => setClassroom(response.data.data));
    }

    const columns = [
        {title: 'Adı', field: 'name', validate: rowData => rowData.name !== ''},
        {title: 'Soyadı', field: 'surname', validate: rowData => rowData.name !== ''},
        {title: 'Kurum No', field: 'academyNo'},
        {title: 'Kimlik No', field: 'identityNo'},
        {title: 'Cinsiyet', field: 'sex', lookup: {'MALE': 'Erkek', 'FEMALE': 'Kız'}},
        {
            title: 'Doğum Ay',
            field: 'birthMonth',
            lookup: {
                1: 'Ocak',
                2: 'Şubat',
                3: 'Mart',
                4: 'Nisan',
                5: 'Mayıs',
                6: 'Haziran',
                7: 'Temmuz',
                8: 'Ağustos',
                9: 'Eylül',
                10: 'Ekim',
                11: 'Kasım',
                12: 'Aralık'
            }
        },
        {title: 'Doğum Yıl', field: 'birthYear', type: 'numeric', validate: rowData => rowData.birthYear > 1900 && rowData.birthYear < 2050}
    ];

    useEffect(() => {
        getClassroom();
        getStudents();
    }, []);

    return (
        <div>
            <h2>ÖĞRENCİLER - {classroom?.name ? (classroom.name + ' (' + classroom.classLevel.name + ')') : ''}</h2>

            {classroom && <Classroom classroom={classroom} measureId={null} showActionButton={false}/>}

            <MaterialTable
                title={classroom?.name ? (classroom.name + ' (' + classroom.classLevel.name + ')') : ''}
                icons={tableIcons}
                columns={columns}
                data={students}

                editable={{
                    onRowAdd: newData =>
                        new Promise((resolve, reject) => {
                            const changedStudents = [];

                            const student = {
                                id: newData.id,
                                classroomId: classroom.id,
                                name: newData.name,
                                surname: newData.surname,
                                academyNo: newData.academyNo,
                                identityNo: newData.identityNo,
                                sex: newData.sex,
                                birthMonth: newData.birthMonth,
                                birthYear: newData.birthYear
                            };

                            changedStudents.push(student);

                            StudentApi.saveAll(changedStudents).then((response) => {
                                getStudents();
                                enqueueSnackbar('Öğrenci(ler) kayıt işlemi tamamlandı', {
                                    variant: 'success'
                                });
                                resolve();
                            }).catch(error => {
                                    error.response.data?.uiMessages?.errors.map((err) => (
                                        enqueueSnackbar(err?.message, {
                                            variant: 'error'
                                        })
                                    ))
                                    reject();
                                }
                            )
                        }),

                    onBulkUpdate: changes =>
                        new Promise((resolve, reject) => {
                            const rows = Object.values(changes);
                            const changedStudents = [];
                            rows.map(row => {
                                const newData = row.newData;
                                const student = {
                                    id: newData.id,
                                    classroomId: classroom.id,
                                    name: newData.name,
                                    surname: newData.surname,
                                    academyNo: newData.academyNo,
                                    identityNo: newData.identityNo,
                                    sex: newData.sex,
                                    birthMonth: newData.birthMonth,
                                    birthYear: newData.birthYear

                                };
                                changedStudents.push(student);
                            })

                            StudentApi.saveAll(changedStudents).then((response) => {
                                getStudents();
                                enqueueSnackbar('Öğrenci(ler) kayıt işlemi tamamlandı', {
                                    variant: 'success'
                                });

                                resolve();
                            }).catch(error => {
                                    error.response.data?.uiMessages?.errors.map((err) => (
                                        enqueueSnackbar(err?.message, {
                                            variant: 'error'
                                        })
                                    ))
                                    reject();
                                }
                            )

                        })
                }}

                options={{
                    exportButton: true,
                    paging: false,
                    actionsColumnIndex: -1,
                    addRowPosition: "first"
                }}

                localization={tableLocationsTR}
            />
            <Grid container direction="row" alignItems="center" alignContent="stretch" style={{marginTop: '20px'}}>
                <Grid item>

                </Grid>
            </Grid>

        </div>
    );
};

export default StudentList;
